import React, { useEffect, useState } from 'react';

import { Image, ImageProps as ExpoImageProps } from 'expo-image';
import { useResponsiveValue } from '@gf/cross-platform-lib/hooks';
import { ImageProps } from './Image.type';
import { getImageSrc } from './helpers';
import { Platform } from 'react-native';

/**
 *
 * @param src: source image for required from local, directly import/require & net work images.
 * @param name: as src but only for local images.
 * @param width: width in px
 * @param height: height in px
 * @param tintColor: color for the image
 * @param alt: alt text for accessibility
 * @param fallbackimg: fallback image if the provided src throws an error
 * @param handleErrorImg: function to handle error image
 */

export const GFImage = ({
  name,
  src,
  width = 16,
  height = 16,
  tintColor,
  alt,
  fallbackImg,
  handleErrorImg = () => {}
}: ImageProps) => {
  const [imgSource, setImgSource] = useState(getImageSrc(src, name));

  useEffect(() => {
    setImgSource(getImageSrc(src, name));
  }, [src, name]);

  const onError = () => {
    setImgSource(getImageSrc(fallbackImg));
    handleErrorImg();
  };

  const w = useResponsiveValue(width) as number;
  const h = useResponsiveValue(height) as number;
  const imgProps: ExpoImageProps = {
    accessibilityLabel: alt || '',
    source: imgSource,
    style: {
      resizeMode: 'contain',
      tintColor: tintColor,
      width: w,
      height: h
    }
  };
  if (Platform.OS === 'web') {
    imgProps.responsivePolicy = 'initial';
  }
  return imgSource?.uri === '' ? null : (
    <>{imgSource && <Image tintColor={tintColor} {...imgProps} onError={onError} />}</>
  );
};
