// You can include shared interfaces/types in a separate file
// and then use them in any component by importing them. For
// example, to import the interface below do:
//
// import { User } from 'path/to/interfaces';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { DiscountType } from './TicketPromotion';
import { AdditionalFieldTypes } from './AdditionalInformationForm';
import { OrderDTO } from '@gf/cross-platform-lib/models';
import { PurchasedTicket } from './PurchasedTicket';
import { Event } from './Event';
import { Dictionary } from '../utils';

export * from './Activity';
export * from './AdditionalInformationForm';
export * from './ChartInstance';
export * from './Event';
export * from './EventSeason';
export * from './IGeoLocation';
export * from './ProductSales';
export * from './ProductSalesMap';
export * from './ProductSeating';
export * from './ProductTicket';
export * from './ProductType';
export * from './PurchasedTicket';
export * from './School';
export * from './SchoolDistrictInfo';
export * from './Season';
export * from './User';
export * from './UserAccountContext';
export * from './Venue';
export * from './Sponsor';
export * from './ReservedSeating';
export * from './Team';
export * from './TicketPromotion';
export * from './HoldToken';
export * from './AvailabilityReport';
export * from './IPaginationResponse';
export * from './IPaginationRequest';
export * from './ISearchEventFilterParams';
export * from './OrderHistory';

export interface QueryParams {
  [key: string]: any;
}

export interface SchoolQueryParams {
  gofanPageEnabled: boolean;
}

interface Set<T> {
  add(value: T): this;
  clear(): void;
  delete(value: T): boolean;
  forEach(callbackfn: (value: T, value2: T, set: Set<T>) => void, thisArg?: any): void;
  has(value: T): boolean;
  readonly size: number;
}

type GoFanAppPage = keyof typeof GOFAN_APP_PAGES;
export type RouteName = (typeof GOFAN_APP_PAGES)[GoFanAppPage]['name'];
export type RootStackParamList = {
  [routeName: RouteName]: {
    name: string;
    params: {
      [key: string]: string;
    };
  };
};

export interface Field {
  displayOnTicket: boolean;
  id: number;
  name: string;
  value: string;
  formFieldId: number;
  fieldType: AdditionalFieldTypes;
}

export interface PatchField {
  id: number;
  value: string;
  name?: string;
  displayOnTicket?: boolean;
  formFieldId?: number;
  fieldType?: AdditionalFieldTypes;
}

export interface Form {
  eventId: number;
  fields: Field[];
  formId: number;
  id: number;
  orderId: number;
  purchasedTicketId: number;
}

export interface FormResponse {
  content: Form[];
}

export interface PromotionData {
  accessLevel: string;
  code: string;
  deleted: boolean;
  discountType: DiscountType;
  endDateTime: Date;
  id: string;
  label: number;
  limit: number;
  name: string;
  productAssociations: [
    {
      productId: number;
      productSpecificLimit: number;
    }
  ];
  required: boolean;
  startDateTime: Date;
  value: number;
}

export interface Promotion {
  _embedded: {
    promotion: PromotionData;
  };
  createAt: string;
  createdBy: string;
  eventId: number;
  id: string;
  promoCode: string;
  promoId: number;
  quantityLocked: number;
  quantityRequested: number;
  seasonId: number;
  state: string;
  updateAt: string;
  updatedBy: string;
}

export interface DiscountPromoInfo {
  accessCode: string;
  id: number;
  code: string;
  discount: number;
  discountType?: DiscountType;
  value?: number;
}

export interface DiscountInfo {
  total: number;
  promoCodes: DiscountPromoInfo[];
}

export interface PromotionLockResponseError {
  body: null;
  message: string;
  statusCode: number;
}

export interface RenewalSeats {
  category: string;
  row: string;
  seat: { id?: string; label: string; seatInfo?: string; seatsIoLabel: string; ticketId: string };
  section: string;
}

export enum SeasonRenewalStatus {
  DECLINING = 'DECLINING',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  RENEWED = 'RENEWED',
  NORESPONSE = 'NORESPONSE',
  NOTFOUND = 'NOTFOUND'
}

export type PromotionLockResponse = Promotion | PromotionLockResponseError;

export interface SafeFetchResponse<T> {
  status: number;
  data: T | null;
  error?: {
    message: string;
    code: number;
    data: any;
  } | null;
}

export interface RetrySettings {
  retry: number | undefined | boolean;
}

export interface IUseGetOrderQueryOptions {
  retry: number | undefined | boolean;
  onSuccess?: (queryResponse: SafeFetchResponse<OrderDTO>) => void;
  onError?: () => void;
  delayFirstCall?: number;
  showLayoutOnError?: boolean;
}

export interface GetEventsQueryContext {
  isFromTicketCarousel?: boolean;
}

export type UseTicketTrackingProps = {
  tickets: Dictionary<PurchasedTicket[]>;
  event: Event;
  boxOffice: boolean;
  isTransferred: boolean;
  orderId?: string;
};

export type PrintTicketTrackingProps = {
  tickets: PurchasedTicket[];
  event: Event;
  page: string;
};

export interface ISendMailParams {
  to?: string | undefined;
  subject: string;
  body: string;
  replyTo?: string | undefined;
  email: string;
}
