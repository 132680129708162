import React, { useCallback } from 'react';
import { StyledText } from '../StyledText';
import { View, TextInput, Pressable } from 'react-native';
import { Carbonicons } from '@gf/cross-platform-lib/components/Icons';
import styled from '@gf/cross-platform-lib/styled-components';
import { Controller, RegisterOptions } from 'react-hook-form';
import { useAuthentication, useReturnKeyType } from '@gf/cross-platform-lib/hooks';
import { debouncedPromise, testProperties, formFields } from '@gf/cross-platform-lib/utils';
import {
  EMAIL_ALREADY_IN_USE,
  EMAIL_FORMAT_PATTERN,
  EMAIL_NOT_IN_USE,
  MAPPING_FIREBASE_CODE_AND_ERROR_MESSAGES
} from '@gf/cross-platform-lib/constants';
import { styles, TextInputSize } from './RHFInputProps.styles';
import { useFetchValidTldsQuery } from '@gf/cross-platform-lib/modules/AcquisitionV2/queries/validationQueries';
import isEmpty from 'lodash/isEmpty';
import { gray40, red60 } from '@gf/cross-platform-lib/utils';

export enum InputEmailType {
  GUEST,
  SIGN_IN,
  SIGN_UP,
  RESET_PW
}

export interface IRHFEmailInputProps {
  email?: string;
  control: any;
  defaultValue?: string;
  error?: string;
  inputType: InputEmailType;
  autoFocus?: boolean;
  title?: string;
  required?: boolean;
  requiredMessage?: string;
  inputSize?: TextInputSize;
  placeholder?: string;
}

export function RHFEmailInput({
  title = 'Email',
  required = false,
  requiredMessage,
  inputSize = 'lg',
  email,
  control,
  defaultValue,
  error,
  inputType,
  autoFocus,
  placeholder = ''
}: IRHFEmailInputProps) {
  const returnKeyType = useReturnKeyType();
  const { isEmailAlreadyRegistered } = useAuthentication();
  const { data: validTlds } = useFetchValidTldsQuery();

  // useCallback needed to maintain the same debounced function reference throughout renders
  // debouncedPromise needed to limit the calls to the api as the user types
  const debouncedEmailExists = useCallback(
    debouncedPromise(async (email: string) => {
      const emailAlreadyRegistered = await isEmailAlreadyRegistered(email.toLowerCase());

      switch (inputType) {
        case InputEmailType.SIGN_UP:
          return emailAlreadyRegistered ? MAPPING_FIREBASE_CODE_AND_ERROR_MESSAGES[EMAIL_ALREADY_IN_USE] : true;
        case InputEmailType.RESET_PW:
          return emailAlreadyRegistered ? true : MAPPING_FIREBASE_CODE_AND_ERROR_MESSAGES[EMAIL_NOT_IN_USE];
        default:
          throw 'validation case not supported';
      }
    }, 500),
    []
  );

  const rules = (): RegisterOptions => {
    const defaultEmailRules = {
      pattern: { value: EMAIL_FORMAT_PATTERN, message: 'Enter a valid email address.' },
      required: {
        value: true,
        message: typeof requiredMessage === 'string' ? requiredMessage : 'Email address is required.'
      },
      validate: {
        validTLD: async inputValue => {
          if (!validTlds || isEmpty(validTlds)) {
            return true;
          }
          const topLevelDomain: string = inputValue?.split('.').pop().toUpperCase();
          return validTlds.includes(topLevelDomain) ? true : 'Please enter a valid email';
        }
      }
    } as RegisterOptions;

    switch (inputType) {
      case InputEmailType.GUEST:
        return { ...defaultEmailRules, value: email?.toLowerCase() } as RegisterOptions;
      case InputEmailType.SIGN_IN:
        return { ...defaultEmailRules } as RegisterOptions;
      case InputEmailType.SIGN_UP:
        return {
          ...defaultEmailRules,
          validate: {
            ...defaultEmailRules.validate,
            emailExists: debouncedEmailExists
          }
        } as RegisterOptions;
      case InputEmailType.RESET_PW:
        return {
          ...defaultEmailRules,
          validate: {
            ...defaultEmailRules.validate,
            emailExists: debouncedEmailExists
          }
        } as RegisterOptions;
    }
  };

  return (
    <Wrapper>
      <StyledText color='textPrimary' typeToken='label01' style={{ marginBottom: 8 }}>
        {required ? `${title} *` : title}
      </StyledText>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name='email'
        rules={rules()}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputWrapper error={error}>
            <EmailInput
              autoCapitalize='none'
              autoFocus={autoFocus}
              onBlur={onBlur}
              onChangeText={(value: string) => onChange(value.trim())}
              value={value}
              maxLength={255}
              textBreakStrategy='balanced'
              placeholder={placeholder}
              placeholderTextColor={gray40}
              inputSize={inputSize}
              invalid={!!error}
              returnKeyType={returnKeyType}
              aria-label='enter email'
              {...testProperties(formFields.email)}
            />
            {error && (
              <TailingIcon disabled>
                <Carbonicons name='warning-filled' size={16} color={red60} />
              </TailingIcon>
            )}
          </InputWrapper>
        )}
      ></Controller>

      {error && (
        <StyledText
          color='textError'
          typeToken='label01'
          style={{ marginTop: 8 }}
          {...testProperties(formFields.validateMsg)}
        >
          {error}
        </StyledText>
      )}
    </Wrapper>
  );
}

const InputWrapper = styled(View)`
  ${styles.inputWrapper}
`;

const Wrapper = styled(View)``;

const EmailInput = styled(TextInput)`
  ${styles.input}
`;

const TailingIcon = styled(Pressable)`
  ${styles.tailingIcon}
`;
