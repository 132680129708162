import React, { createContext, useContext } from 'react';
import LDNativeClient from 'launchdarkly-react-native-client-sdk';
import { LDClient as LDWebClient } from 'launchdarkly-js-client-sdk';
import { LaunchDarklyContextAttributes } from '@gf/cross-platform-lib';

export interface LaunchDarklyProviderProps {
  children: React.ReactNode;
}

export type ContextKind = 'school' | 'event' | 'user' | 'payment';

export interface MultiContextBase {
  kind: 'multi';
}
interface BaseContext {
  kind: ContextKind;
  key: string;
  anonymous: boolean;
}

export interface PaymentContext extends BaseContext {
  kind: 'payment';
  schoolIds: string[];
  eventIds: string[];
  email: string;
}

export interface SchoolContext extends BaseContext {
  kind: 'school';
  schoolId: string;
  eventId?: string;
}

export interface EventContext extends BaseContext {
  kind: 'event';
  eventId: string | number;
  schoolId?: string;
}

export interface UserContext extends BaseContext {
  kind: 'user';
  name?: string; // name of the context in LaunchDarkly
  fanEmail?: string;
  fanFirstName?: string;
  fanLastName?: string;
}

export interface ITeamSitePromotion {
  displayName: string;
  src: string;
}

export type AnyContext = SchoolContext | EventContext | UserContext | PaymentContext;

export type MultiContext = {
  [key: string]: 'multi' | AnyContext;
};

export interface ILaunchDarklyContextValue {
  isReady: boolean;
  isEnabled: boolean;
  client: null | LDNativeClient | LDWebClient;
  ldContext: MultiContext;
  features: {
    [key: string]:
      | { type: 'boolean'; variation: boolean }
      | { type: 'number'; variation: number }
      | { type: 'string'; variation: string }
      | { type: 'json'; variation: Object };
  };
  listeners: {
    [key: string]: Function;
  };
  listenerUnsubscribes: {
    [key: string]: Function;
  };
  updateLaunchDarklyContext: (
    kind: ContextKind,
    key: string,
    attributes: LaunchDarklyContextAttributes
  ) => Promise<void>;
}

interface ILaunchDarklyRef {
  current: ILaunchDarklyContextValue;
}

export const DEFAULT_ANONYMOUS_USER: UserContext = {
  kind: 'user',
  key: 'anonymous-user',
  anonymous: true,
  name: '',
  fanEmail: '',
  fanFirstName: '',
  fanLastName: ''
};

export const defaultLaunchDarklyContextValue: ILaunchDarklyContextValue = {
  isReady: false,
  isEnabled: false,
  client: null,
  // Define default Launch Darkly context here
  ldContext: {
    kind: 'multi',
    user: DEFAULT_ANONYMOUS_USER
  },
  // Define default flag behavior here
  features: {
    'profile-lite-enabled': {
      type: 'boolean',
      variation: false
    },
    'gofan-minimum-supported-version': {
      type: 'string',
      variation: '5.6.0'
    },
    'gofan-prompt-unsupported-versions': {
      type: 'boolean',
      variation: false
    },
    'view-schools-from-district': {
      type: 'boolean',
      variation: false
    },
    'school-context-experiment': {
      type: 'boolean',
      variation: false
    },
    'event-context-experiment': {
      type: 'boolean',
      variation: false
    },
    'user-context-experiment': {
      type: 'boolean',
      variation: false
    },
    'user-multicontext-experiment': {
      type: 'boolean',
      variation: false
    },
    'bypass-recaptcha': {
      type: 'boolean',
      variation: false
    },
    'bypass-recaptcha-orders-only': {
      type: 'boolean',
      variation: false
    },
    'fail-recaptcha': {
      type: 'boolean',
      variation: false
    },
    'fundraiser-upsell-experiment': {
      type: 'boolean',
      variation: false
    },
    'event-sponsorship-enabled': {
      type: 'boolean',
      variation: false
    },
    'digital-ticket-sponsorship-enabled': {
      type: 'boolean',
      variation: false
    },
    'teams-sites-promotion': {
      type: 'json',
      variation: {}
    },
    'teams-in-gofan': {
      type: 'boolean',
      variation: false
    },
    'teams-in-gofan-favoriting': {
      type: 'boolean',
      variation: false
    },
    'payment-configuration-for-express-checkout': {
      type: 'string',
      variation: ''
    },
    'payment-configuration-for-payment-element': {
      type: 'string',
      variation: ''
    },
    'payment-configuration-for-payment-sheet': {
      type: 'string',
      variation: ''
    },
    'ads-enabled': {
      type: 'boolean',
      variation: false
    },
    'order-history-enabled': {
      type: 'boolean',
      variation: true
    },
    'enable-favorite-school-tab': {
      type: 'boolean',
      variation: false
    },
    'ads-enabled-mapping': {
      type: 'json',
      variation: {}
    },
    'userService-enabled': {
      type: 'boolean',
      variation: false
    }
  },
  listeners: {},
  listenerUnsubscribes: {},
  updateLaunchDarklyContext: async () => {
    /* no operation */
  }
};

export const LaunchDarklyContext = createContext<ILaunchDarklyContextValue>(defaultLaunchDarklyContextValue);

export const useLaunchDarklyContext = () => {
  const context = useContext(LaunchDarklyContext);
  return context;
};

export const LaunchDarklyRef: ILaunchDarklyRef = {
  current: defaultLaunchDarklyContextValue
};
