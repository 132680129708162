import { School } from './School';
import { Activity } from './Activity';
import { ProductSeating } from './ProductSeating';
import { ProductSalesMap } from './ProductSalesMap';
import { Venue } from './Venue';
import { Level } from './Level';
import { InformationForm } from './AdditionalInformationForm';
import { SalesInfo } from './SalesInfo';

export interface AccessCodeInformation {
  code: string;
  id?: string;
  createAt?: number;
}

export enum EventTypeEnum {
  TICKET = 'TICKET',
  MOBILEPASS = 'MOBILEPASS',
  SEASON = 'SEASON'
}

export type EventType = EventTypeEnum | string;

export interface EventInterface {
  canceled?: boolean;
  id: string;
  name: string;
  title: string;
  school: School;
  type: EventType;
  optionalDescription?: string;
  isAllDayEvent: boolean;
  startDate: string;
  endDate: string;
  venueId: string;
  seasonId: string;
  salesInfo: {
    eventId: string;
    eventCapacity: number;
    isSoldOut: boolean;
    totalSaleQuantity: number;
    saleAvailable: number;
    schoolSalesMap: string;
    productSalesMap: ProductSalesMap;
    seasonId: string;
    seasonSoldOut: boolean;
    seasonTotalRefundedQuantity: number;
    seasonTotalRemainingQuantity: number;
    seasonTotalSaleQuantity: number;
    eventTotalRemainingQuantity: number;
  };
  productSalesMap?: {};
  hasDirectError: boolean;
  isSingleDay: boolean;
  isMultipleDays: boolean;
  optionalTypeDescription: string; //we should rename this - typeDescription maybe?
  timeZone: string;
  products: ProductSeating[];
  schoolsTicket?: { schoolId: string; schoolLimit: number }[];
  endDateTime: string;
  isPostSeason: boolean;
  isMobilePass: boolean;
  genders: string[];
  levels: { genders: string[]; levelName: string }[];
  schoolsFeaturedOn?: string[];
  activity: Activity;
  startDateTime: string;
  schoolHuddleId: string;
  eventTypeName: string;
  opponentSchoolId: string;
  taggedSchoolHuddleIds: string[];
  redemptionWindow: number;
  ticketTypes: ProductSeating[];
  disabledForIndividualSale: boolean;
  archived: boolean;
  venue?: Venue;
  theme?: string;
  alert?: string;
  description?: string;
  maxCapacity?: number;
  publishDateTime: string; //publishDateTime
  // form?: [];
  salesEndDateTime: string;
  salesStartDateTime: string;
  enableEventValidation?: boolean;
  eventValidationStartsBefore?: number;
  ticketLimitPerOrder?: number;
  goal?: string;
}

export interface EventCombined {
  activity?: Activity;
  alert?: string;
  archived?: boolean;
  canceled?: boolean;
  description?: string;
  disabledForIndividualSale?: boolean;
  enableEventValidation?: boolean;
  endDate: string;
  endDateTime: string;
  eventIds?: string[];
  eventTypeName?: EventType;
  eventValidationStartsBefore?: number;
  financialSchoolID?: string;
  financialSchoolIndustryCode?: string;
  financialSchoolName?: string;
  financialSchoolState?: string;
  financialSchoolType?: string;
  form?: InformationForm;
  genders: string[];
  hasDirectError?: boolean;
  id: string;
  isAllDayEvent: boolean;
  isMobilePass?: boolean;
  isMultipleDays?: boolean;
  isPostSeason?: boolean;
  isSingleDay?: boolean;
  isSeason?: boolean;
  levels?: Level[];
  maxCapacity?: number;
  name?: string;
  opponentSchoolId?: string;
  optionalDescription?: string;
  optionalTypeDescription: string;
  products?: ProductSeating[];
  productSalesMap?: {};
  publishDateTime?: string;
  redemptionWindow?: number;
  reservedSeatingAvailable?: boolean;
  salesInfo?: SalesInfo;
  salesStartDateTime?: string;
  salesEndDateTime?: string;
  school?: School;
  schoolHuddleId?: string;
  schoolsFeaturedOn?: string[];
  schoolsTicket?: {
    schoolId: string;
    schoolLimit: number;
  }[];
  seasonId?: string;
  startDate: string;
  startDateTime: string;
  taggedSchoolHuddleIds: string[];
  theme?: string;
  ticketLimitPerOrder?: number;
  ticketDistribution?: boolean;
  ticketTypes: ProductSeating[];
  timeZone: string;
  title?: string;
  type: EventType;
  venue?: Venue;
  venueId?: string;
  goal?: string;
  disableQr?: boolean;
}

export interface GetStartDateTitleProps {
  startDateTime: string;
  endDateTime: string;
  timeZone: string;
  isAllDayEvent: boolean;
  isSeason: boolean;
  hideTimeStamp?: boolean;
}

export type Event = EventCombined;
