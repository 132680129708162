import { useMemo } from 'react';
import { useGlobalState } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { SchoolCart } from '../models/index';
import { EventTypeEnum } from '@gf/cross-platform-lib/interfaces';
import { roundingDecimalNumber } from '@gf/cross-platform-lib/utils';

export interface CartState {
  cartSchools: SchoolCart[];
  cartTotal: {
    total: number;
    subTotal: number;
    serviceFees: number;
    totalDiscount: number;
  };
  hasMobilePass?: boolean;
  totalNumberOfTickets?: number;
  isEmpty?: boolean;
  isExpired?: boolean;
  eventIds: Set<string>;
  schoolIds: Set<string>;
  lastTicketAddedDate?: Date;
}

export const useCartState = (): CartState => {
  const { cartSchools, isExpired, lastTicketAddedDate } = useGlobalState(state => state.cart);

  const { hasMobilePass, cartTotal, totalNumberOfTickets, eventIds, schoolIds } = useMemo(() => {
    let hasMobilePass = false;
    let subTotal = 0;
    let serviceFees = 0;
    let totalNumberOfTickets = 0;
    let totalDiscount = 0;
    const eventIds = new Set<string>();
    const schoolIds = new Set<string>();

    for (const school of cartSchools) {
      schoolIds.add(school.id);
      for (const event of school.events) {
        eventIds.add(event.id);
        if (event.type === EventTypeEnum.MOBILEPASS) {
          hasMobilePass = true;
        }
        for (const ticket of event.tickets) {
          subTotal += ticket.price * ticket.quantity;
          serviceFees += ticket.fee * ticket.quantity;
          totalNumberOfTickets += ticket.quantity;
          totalDiscount += ticket.promotion?.totalDiscount ? ticket.promotion?.totalDiscount : 0;
        }
      }
    }
    const total = subTotal + serviceFees - totalDiscount;

    const cartTotal = {
      subTotal,
      serviceFees,
      total: roundingDecimalNumber(total, true),
      totalDiscount
    };

    return {
      hasMobilePass,
      cartTotal,
      totalNumberOfTickets,
      eventIds,
      schoolIds
    };
  }, [cartSchools]);

  return {
    cartSchools,
    hasMobilePass,
    cartTotal,
    totalNumberOfTickets,
    isEmpty: cartSchools.length === 0,
    isExpired,
    eventIds,
    schoolIds,
    lastTicketAddedDate
  };
};
