import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import styled from 'styled-components';
import { DrawerProps, getRightDrawerSpringValues } from '../drawerUtil';
import { styles } from '../base.styles';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { Dimensions } from 'react-native';
import { APP_FOOTER_SIZE, APP_HEADER_SIZE } from '../drawerConstants';

const windowHeight = Dimensions.get('window').height;

export const RightDrawer = ({ content, drawerOpen, drawerSize, isFullScreen }: DrawerProps) => {
  const { isMobile, isNativeApp } = useMediaQuery();

  const springStyle = useSpring({
    ...getRightDrawerSpringValues(drawerOpen)
  });

  return isFullScreen ? (
    <RightDrawerContainerFullScreen isNative={isNativeApp.toString()}>{content}</RightDrawerContainerFullScreen>
  ) : (
    <RightDrawerContainer
      isMobile={isMobile.toString()}
      isNative={isNativeApp.toString()}
      drawerSize={drawerSize?.toString()}
      maxHeight={
        isMobile
          ? (windowHeight - APP_HEADER_SIZE - APP_FOOTER_SIZE).toString() + 'px'
          : windowHeight - APP_HEADER_SIZE + 'px'
      }
      style={{
        ...springStyle
      }}
    >
      {content}
    </RightDrawerContainer>
  );
};

const RightDrawerContainer = styled(animated.div)`
  ${styles.rightDrawerContainer}
`;
const RightDrawerContainerFullScreen = styled(animated.div)`
  ${styles.rightDrawerContainerFullScreen};
`;
export default RightDrawer;
